import { Container } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { SimpleCard } from '../../../../matx'
import AccountDetails from '../accounts-manager/accountDetails/AccountDetails'
import { Form } from 'react-bootstrap'
import { updateAnalysisStatus, getAnalysisStatus } from '../../../services/APIClient'
import Loading from '../../../components/Loading'

export default function AccountToApproveDetails({ id }) {
  return (
    <div className="First card">
      <AccountDetails id={id} />
      <SimpleCard title="Analyse:">
        <Container>
          <AccountButtonsToApprove accountId={id} />
        </Container>
      </SimpleCard>
    </div>
  )
}

function AccountButtonsToApprove({ accountId }) {
  const [comment, setComment] = useState('')
  const [currentanalysisStatus, setCurrentanalysisStatus] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)

  function reloadCurrentAnalysisStatus(accountId) {
    getAnalysisStatus(accountId)
      .then((responseBody) => {
        setCurrentanalysisStatus(responseBody)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    reloadCurrentAnalysisStatus(accountId)
  }, [accountId])

  const updateEndStatus = async function (message, status) {
    if (window.confirm(message)) {
      setIsLoading(true)
      const body = {
        comment: comment,
        status: status,
      }
      updateAnalysisStatus(accountId, body)
        .then(() => reloadCurrentAnalysisStatus(accountId))
        .finally(() => setIsLoading(false))
    }
  }

  if (isLoading) {
    return <Loading />
  }
  if (currentanalysisStatus !== 'OPEN') {
    return <p>You have already analysed this account. Status: {currentanalysisStatus}</p>
  }

  return (
    <>
      <center>
        <div>
          <Form.Label>Comments:</Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            name="reasons"
            onChange={(event) => setComment(event.target.value)}
            required
          />
        </div>

        <div className="row" style={{ paddingTop: '30px' }}>
          <div className="col-md-6 col-4">
            <button
              type="button"
              className="btn btn-success"
              disabled={comment === ''}
              onClick={async () => {
                updateEndStatus('Are you sure you want to update to APPROVED?', 'APPROVED')
              }}
            >
              APPROVED
            </button>
          </div>

          <div className="col-md-6 col-4">
            <button
              type="button"
              className="btn btn-danger"
              disabled={comment === ''}
              onClick={async () => {
                updateEndStatus('Are you sure you want to update to DISAPPROVED?', 'DISAPPROVED')
              }}
            >
              DISAPPROVED
            </button>
          </div>
        </div>
      </center>
    </>
  )
}

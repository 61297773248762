import { Component } from 'react'
import { SimpleCard } from '../../../../matx'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import React from 'react'
import { transactionDetails } from '../../../services/APIClient'
import 'bootstrap/dist/css/bootstrap.min.css'
import { convertToBrazilianTimeZone, currencyWithTwoDecimals } from '../../../services/FormatterService'
import { NavLink } from 'react-router-dom'

export default class PayinTransactionDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      details: null,
      id: this.props.id,
    }
  }

  componentDidMount() {
    transactionDetails(this.state.id).then((resp) => {
      this.setState({ details: resp })
    })
  }

  render() {
    return (
      <div className="First card">
        <SimpleCard title="Transaction Details">
          <div>
            {this.state.details === null ? (
              ''
            ) : (
              <Container>
                <h5>Transaction:</h5>
                <BTable striped bordered hover>
                  <tbody>
                    <tr>
                      <td>Id</td>
                      <td>{this.state.details.id}</td>
                    </tr>
                    <tr>
                      <td>Externalized Id</td>
                      <td>{this.state.details.externalId}</td>
                    </tr>
                    <tr>
                      <td>Business line</td>
                      <td>{this.state.details.businessLine}</td>
                    </tr>
                    <tr>
                      <td>Merchant soft descriptor</td>
                      <td>
                        <NavLink to={`/account/${this.state.details.merchantId}`} end>
                          {this.state.details.merchantSoftDescriptor}
                        </NavLink>
                      </td>
                    </tr>
                    <tr>
                      <td>Merchant name</td>
                      <td>{this.state.details.merchantName}</td>
                    </tr>
                    <tr>
                      <td>Intermediary soft descriptor</td>
                      <td>
                        <NavLink to={`/account/${this.state.details.intermediaryId}`} end>
                          {this.state.details.intermediarySoftDescriptor}
                        </NavLink>
                      </td>
                    </tr>
                    <tr>
                      <td>Intermediary name</td>
                      <td>{this.state.details.intermediaryName}</td>
                    </tr>
                    <tr>
                      <td>Reference</td>
                      <td>{this.state.details.reference}</td>
                    </tr>
                    <tr>
                      <td>Merchant order Id</td>
                      <td>{this.state.details.collectInfo.transactionInfo.merchantOrderId}</td>
                    </tr>
                    <tr>
                      <td>Payment method</td>
                      <td>{this.state.details.paymentMethod}</td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>{currencyWithTwoDecimals(this.state.details.transaction.amount)}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{this.state.details.transaction.status}</td>
                    </tr>
                    {this.state.details.transaction.statusDetails === undefined &&
                    this.state.details.returnMessage !== undefined ? (
                      ''
                    ) : (
                      <tr>
                        <td>Status details</td>
                        <td>{this.state.details.transaction.statusDetails}</td>
                      </tr>
                    )}
                    {this.state.details.returnMessage !== undefined &&
                      this.state.details.transaction.statusDetails !== this.state.details.returnMessage && (
                        <tr>
                          <td>Return Message</td>
                          <td>{this.state.details.returnMessage}</td>
                        </tr>
                      )}
                    <tr>
                      <td>Created At</td>
                      <td>{convertToBrazilianTimeZone(this.state.details.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>Updated At</td>
                      <td>{convertToBrazilianTimeZone(this.state.details.updatedAt)}</td>
                    </tr>
                  </tbody>
                </BTable>

                {this.state.details.paymentMethod === 'CREDIT_CARD' ? (
                  <CreditCardTransactionDetails transactionInfo={this.state.details.collectInfo.transactionInfo} />
                ) : (
                  <></>
                )}

                {this.state.details.collectInfo.transactionInfo?.fx ? (
                  <FxDetails fx={this.state.details.collectInfo.transactionInfo.fx} />
                ) : (
                  <></>
                )}

                <CustomerDetails
                  kycName={this.state.details.kycName}
                  customerId={this.state.details.customerId}
                  customerInfo={this.state.details.collectInfo.customerInfo}
                  billing={this.state.details.collectInfo.billing}
                  shipping={this.state.details.collectInfo.shipping}
                />
                <ItemsDetails items={this.state.details.collectInfo.items} />
              </Container>
            )}
          </div>
        </SimpleCard>
      </div>
    )
  }
}

class ItemsDetails extends Component {
  render() {
    const { items } = this.props
    if (typeof items === 'undefined' || items.length === 0) return <React.Fragment />

    return (
      <React.Fragment>
        <h5>Items:&nbsp;&nbsp;</h5>
        {items.map((item, index) => (
          <div key={index}>
            <h5>{item.sku}</h5>
            <BTable striped bordered hover>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{item.name}</td>
                </tr>
                <tr>
                  <td>Sku</td>
                  <td>{item.sku}</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>{item.type}</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>{item.amount}</td>
                </tr>
                <tr>
                  <td>Quantity</td>
                  <td>{item.quantity}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{item.description}</td>
                </tr>
                <tr>
                  <td>Shipping type</td>
                  <td>{item.shippingType}</td>
                </tr>
              </tbody>
            </BTable>
          </div>
        ))}
      </React.Fragment>
    )
  }
}

class AddressDetails extends Component {
  render() {
    const { contactInfo, addressType } = this.props
    if (contactInfo === null || typeof contactInfo === 'undefined') return <React.Fragment />

    return (
      <tr>
        <td>{addressType}</td>
        <td>
          Name: {contactInfo.name}
          <br />
          Street name: {contactInfo.address.streetName}
          <br />
          Number: {contactInfo.address.number}
          <br />
          Complement: {contactInfo.address.complement}
          <br />
          District: {contactInfo.address.district}
          <br />
          City: {contactInfo.address.city}
          <br />
          State: {contactInfo.address.state}
          <br />
          ZipCode: {contactInfo.address.zipCode}
          <br />
          Type: {contactInfo.address.type}
          <br />
          Phones: {contactInfo.phones?.map((phone) => phone.rawNumber || phone.number).join(', ')}
        </td>
      </tr>
    )
  }
}

class CustomerDetails extends Component {
  render() {
    const { kycName, billing, shipping, customerInfo, customerId } = this.props
    if (typeof customerInfo == 'undefined') {
      return <React.Fragment></React.Fragment>
    }

    return (
      <React.Fragment>
        <h5>Customer:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Id</td>
              <td>
                <NavLink to={`/customers/${customerId}`} end>
                  {customerId}
                </NavLink>
              </td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{customerInfo.name}</td>
            </tr>
            <tr>
              <td>KYC Name</td>
              <td>{kycName}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{customerInfo.email}</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>{customerInfo.gender}</td>
            </tr>
            {customerInfo.metadata ? (
              <React.Fragment>
                <tr>
                  <td>IP</td>
                  <td>{customerInfo.metadata.ip}</td>
                </tr>
                <tr>
                  <td>Agent OS</td>
                  <td>{customerInfo.metadata.userAgentOS}</td>
                </tr>
                <tr>
                  <td>Agent Name</td>
                  <td>{customerInfo.metadata.userAgentName}</td>
                </tr>
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
            {customerInfo.phones.map((phone, index) => (
              <tr>
                <td>Phones</td>
                <td>{phone.rawNumber || `${phone.prefix} ${phone.number}`}</td>
              </tr>
            ))}
            {customerInfo.documents.map((document, index) => (
              <tr>
                <td>{document.type}</td>
                <td>{document.number}</td>
              </tr>
            ))}
            <tr>
              <td>Document ID</td>
              <td>{customerInfo.documentId}</td>
            </tr>
            <tr>
              <td>PersonType</td>
              <td>{customerInfo.personType}</td>
            </tr>
            <AddressDetails contactInfo={billing} addressType="Billing" />
            <AddressDetails contactInfo={shipping} addressType="Shipping" />
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

const FxDetails = ({ fx }) => {
  return (
    <>
      <h5>Transaction FX details:</h5>
      <BTable striped bordered hover>
        <tbody>
          <tr>
            <td>Amount In Foreign Currency</td>
            <td>{currencyWithTwoDecimals(fx.amountInForeignCurrency, 'US$')}</td>
          </tr>
          <tr>
            <td>{`VET ${fx.foreignCurrency} > BRL`}</td>
            <td>{fx.llFxRateIncludingFxTaxRate}</td>
          </tr>
          <tr>
            <td>FX Amount</td>
            <td>{currencyWithTwoDecimals(fx.fxTaxAmount)}</td>
          </tr>
        </tbody>
      </BTable>
    </>
  )
}

const CreditCardTransactionDetails = ({ transactionInfo }) => {
  return (
    <>
      <h5>Transaction details:</h5>
      <BTable striped bordered hover>
        <tbody>
          <tr>
            <td>Amount</td>
            <td>{currencyWithTwoDecimals(transactionInfo.amount)}</td>
          </tr>
          <tr>
            <td>Installments</td>
            <td>{transactionInfo.installments}</td>
          </tr>
          <tr>
            <td>Subscription</td>
            <td>{transactionInfo.subscription ? 'True' : 'False'}</td>
          </tr>
          <tr>
            <td>Capture</td>
            <td>{transactionInfo.capture ? 'True' : 'False'}</td>
          </tr>
          <tr>
            <td>Bin</td>
            <td>{transactionInfo.cardInfo.bin}</td>
          </tr>
          <tr>
            <td>Last4</td>
            <td>{transactionInfo.cardInfo.last4}</td>
          </tr>
          <tr>
            <td>Holder Name</td>
            <td>{transactionInfo.cardInfo.holderName}</td>
          </tr>
          <tr>
            <td>Expiration year</td>
            <td>{transactionInfo.cardInfo.expirationYear}</td>
          </tr>
          <tr>
            <td>Expiration Month</td>
            <td>{transactionInfo.cardInfo.expirationMonth}</td>
          </tr>
          <tr>
            <td>Holder document Id</td>
            <td>{transactionInfo.cardInfo.holderDocumentId}</td>
          </tr>
        </tbody>
      </BTable>
    </>
  )
}

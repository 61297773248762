import { useState } from 'react'
import { Breadcrumb } from '../../../../matx'
import NetTransfer from './NetTransfer'
import BalanceByProvider from './BalanceByProvider'
import NetTransferRequestedDetails from './NetTransferRequestedDetails'

export default function NetCredited() {
  const [requestedDate, setRequestedDate] = useState(undefined)
  const [dateToRequest, setDateToRequest] = useState(requestedDate)

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Account', path: '/account' }, { name: 'Net Credited' }]} />
      </div>

      <label>Choose the day: </label>
      <input type="date" id="date" name="date" onChange={(event) => setRequestedDate(event.target.value)} />
      <button onClick={() => setDateToRequest(requestedDate)}>Change date</button>
      <div style={{ display: 'grid', gridRowGap: '20px' }}>
        <NetTransfer date={dateToRequest} />
        <NetTransferRequestedDetails />
        <BalanceByProvider date={dateToRequest} />
      </div>
    </div>
  )
}

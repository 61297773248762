import { useEffect, useState } from 'react'
import { getBalancesByProvider } from '../../../services/APIClient'
import Loading from '../../../components/Loading'
import { SimpleCard } from '../../../../matx'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { currencyWithTwoDecimals } from '../../../services/FormatterService'

export default function BalanceByProvider({ date }) {
  const [balanceByProvider, setBalanceByProvider] = useState(undefined)
  const [isLoading, setIsloading] = useState(true)

  useEffect(() => {
    setIsloading(true)
    let mounted = true
    getBalancesByProvider(date)
      .then((balanceByProviderResponse) => {
        if (mounted) {
          setBalanceByProvider(balanceByProviderResponse)
        }
      })
      .finally(() => {
        if (mounted) setIsloading(false)
      })
    return () => (mounted = false)
  }, [date])

  if (isLoading) {
    return <Loading />
  }
  return (
    <>
      <h3>Balances by provider:</h3>
      {balanceByProvider === undefined ? (
        <div>An error has occured</div>
      ) : (
        <div style={{ display: 'grid', gridRowGap: '10px' }}>
          <BalanceByProviderTable title="Balance amounts" balanceByProvider={balanceByProvider.nonReserveBalances} />
          <BalanceByProviderTable
            title="Reserve balance amounts"
            balanceByProvider={balanceByProvider.reserveBalances}
          />
        </div>
      )}
    </>
  )
}

function BalanceByProviderTable({ title, balanceByProvider }) {
  return (
    <div className="First card">
      <SimpleCard title={title}>
        <Table style={{ whiteSpace: 'pre' }}>
          <TableHead>
            <TableRow>
              <TableCell className="px-0">Business line</TableCell>
              <TableCell className="px-0">Balance type</TableCell>
              <TableCell className="px-0">Bank</TableCell>
              <TableCell className="px-0">Merchants count</TableCell>
              <TableCell className="px-0">Total (BRL)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {balanceByProvider.map((item) => (
              <TableRow key={`row${item.balanceType}-${item.businessLine}-${item.provider}`}>
                <TableCell
                  key={`cell-${item.balanceType}-${item.businessLine}-${item.provider}-businesLine`}
                  className="px-0"
                >
                  {item.businessLine}
                </TableCell>
                <TableCell
                  key={`cell-${item.balanceType}-${item.businessLine}-${item.provider}-balanceType`}
                  className="px-0"
                >
                  {item.balanceType}
                </TableCell>
                <TableCell
                  key={`cell-${item.balanceType}-${item.businessLine}-${item.provider}-provider`}
                  className="px-0"
                >
                  {item.provider}
                </TableCell>
                <TableCell
                  key={`cell-${item.balanceType}-${item.businessLine}-${item.provider}-merchantCount`}
                  className="px-0"
                >
                  {item.merchantCount}
                </TableCell>
                <TableCell
                  key={`cell-${item.balanceType}-${item.businessLine}-${item.provider}-amount`}
                  className="px-0"
                >
                  {currencyWithTwoDecimals(item.amount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SimpleCard>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { SimpleCard } from '../../../../matx'
import { accountToApprove } from '../../../services/APIClient'
import { useNavigate } from 'react-router-dom'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { convertToBrazilianTimeZone } from '../../../services/FormatterService'
import Loading from '../../../components/Loading'

const AccountToApproveTable = () => {
  const navigate = useNavigate()
  const [accountList, setAccountList] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    accountToApprove()
      .then((responseBody) => {
        setAccountList(responseBody)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleClick = (id) => {
    navigate(`/account_to_approve/${id}`)
  }

  if (isLoading) {
    return <Loading />
  }
  return (
    <div className="m-sm-30">
      <div className="py-12" />
      <SimpleCard>
        <div>
          <div
            className="MuiToolbar-root MuiToolbar-regular jss8044 MuiToolbar-gutters"
            role="toolbar"
            aria-label="Table Toolbar"
          >
            <div className="jss8046 titleWithSearchBox">
              <div className="jss8050" aria-hidden="true">
                <h6 className="MuiTypography-root jss8051 MuiTypography-h6">Accounts</h6>
              </div>
            </div>
          </div>
          <div className="w-100 overflow-auto">
            <Table style={{ whiteSpace: 'pre' }}>
              <TableHead>
                <TableRow>
                  <TableCell className="px-0">Account id</TableCell>
                  <TableCell className="px-0">TradeName</TableCell>
                  <TableCell className="px-0">SoftDescriptor</TableCell>
                  <TableCell className="px-0">Status</TableCell>
                  <TableCell className="px-0">Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountList.length > 0 ? (
                  accountList.map((account, index) => (
                    <TableRow key={index} onClick={() => handleClick(account.id)}>
                      <TableCell className="px-0 capitalize" align="left">
                        {account.id}
                      </TableCell>
                      <TableCell className="px-0 capitalize" align="left">
                        {account.tradeName}
                      </TableCell>
                      <TableCell className="px-0 capitalize" align="left">
                        {account.softDescriptor}
                      </TableCell>
                      <TableCell className="px-0 capitalize">
                        <div>
                          <small className={`capitalize text-white bg-account-active border-radius-4 px-10 py-10px`}>
                            {account.status}
                          </small>
                        </div>
                      </TableCell>
                      <TableCell>{convertToBrazilianTimeZone(account.createdAt)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>Sem resultados</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </SimpleCard>
    </div>
  )
}

export default AccountToApproveTable
